import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Container,
  Dialog,
  Grid,
  InputBase,
  Slide,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import RedditIcon from "@mui/icons-material/Reddit";
import CloseIcon from "@mui/icons-material/Close";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { Contract } from "ethers";

import { AppContext } from "../../src/utils";
import {
  ToastNotify,
  usePresaleContract,
  useUSDTContract,
  voidAccount,
  provider,
} from "../../src/ConnectivityAssets/hooks";
import tokenAbi from "../../src/ConnectivityAssets/tokenAbi.json";
import { presaleAddress } from "../../src/ConnectivityAssets/environment";
import CustomButton from "../../src/Utils/CustomButton";
import Header from "../../components/Header";

const homeimg = "/images/homeimg.png";
const eth = "/images/eth.png";
const ethBlack = "/images/ethBlack.png";
const usdt = "/images/usdt.png";
const renqLogo = "/images/renqlogo.png";
const loadingGif = "/images/loadingGif.gif";

const Paragraph = styled(Typography)({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "19px",
  color: "#FFFFFF",
});

const Paragraph2 = styled(Typography)({
  fontFamily: "'Prompt'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "19px",
  color: "#FFFFFF",
});

const MianHeading = styled(Typography)({
  fontFamily: "'Prompt'",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "36px",
  lineHeight: "128.2%",
  color: "#FFFFFF",
});

const IconBox = styled(Box)({
  background: "#403866",
  borderRadius: "7px",
  width: "35px",
  height: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    background: "#EF4D84",
  },
  transition: "0.4s",
  cursor: "pointer",
});
const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    height: "30px",
    fontSize: "15px",
    textAlign: "left",
    color: "#fff",
    backgroundColor: "transparent",
    paddingLeft: "15px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
const cardImage = [usdt, eth];

const icons = [
  {
    icon: <TwitterIcon />,
    link: "https://twitter.com/RenQ_Finance",
  },
  {
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/renqfinance",
  },
  {
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/renqfinance/",
  },
  {
    icon: <TelegramIcon />,
    link: "https://t.me/renqfinance",
  },
  {
    icon: <YouTubeIcon />,
    link: "https://www.youtube.com/@renqfinance",
  },
  {
    icon: <RedditIcon />,
    link: "https://www.reddit.com/r/renq",
  },
  {
    icon: <i className="fa-brands fa-medium"></i>,
    link: "https://medium.com/@renq",
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const toLocalFormat = (val) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Home = ({ data }) => {
  const matches = useMediaQuery("(max-width:700px)");
  const { account, connect, signer } = useContext(AppContext);
  const [token, setToken] = useState("USDT");
  const [openDialog, setOpenDialog] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState("");
  const [presaleID, setpresaleID] = useState(0);
  const [tokenDecimals, setTokenDecimals] = useState(18);

  const [nextStagePrice, setNextStagePrice] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [receivedTokens, setreceivedTokens] = useState("");
  const [raisedAmount, setRaisedAmount] = useState(0);
  const [soldTokens, setSoldTokens] = useState(0);
  const [progressBar, setProgessBar] = useState(0);
  const [loading, setloading] = useState(false);
  const [presaleBoxLoading, setPresaleBoxLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [minbuyToken, setMinbuyToken] = useState("");
  const [totalTokenToSell, setTotalTokenToSell] = useState(0);
  const [totalHardCap, setTotalHardCap] = useState(0);
  const [isExcludeMinToken, setIsExcludeMinToken] = useState(false);
  // const [data, setData] = useState(null);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  useEffect(() => {
    // fetchData();
  }, []);

  useEffect(() => {
    console.log(data);

    if (!data?.presaleNumber) {
      return;
    } else setData();

    async function setData() {
      setpresaleID(+data.presaleNumber);
      setNextStagePrice(1 / +data.nextPrice);

      setSoldTokens(Math.floor(+data.totalSale));
      setRaisedAmount(Math.floor(+data.amountRaised));
      setTotalTokenToSell(+data.tokensToSell);
      setTotalHardCap(+data.totalHardCap);
      settokenPrice(1 / +data.price);

      let precentageBar = (+data.amountRaised / +data.totalHardCap) * 100;
      setProgessBar(precentageBar);

      const minToken = await presaleContract.MinTokenTobuy();
      setMinbuyToken(+formatUnits(minToken.toString()));
    }
  }, [data]);

  const presaleContract = usePresaleContract(signer);
  const usdtContract = useUSDTContract(signer);

  async function fetchData() {
    const minToken = await presaleContract.MinTokenTobuy();
    setPresaleBoxLoading(false);
    try {
      // axios
      //   .get("/api/presale", {
      //     // headers: {
      //     //   "Access-Control-Allow-Origin": "*",
      //     //   "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      //     //   "Access-Control-Allow-Headers":
      //     //     "Origin, X-Requested-With, Content-Type, Accept",
      //     //   "Content-Type": "application/json",
      //     // },
      //   })
      axios
        .get("https://profound-smakager-f042ee.netlify.app/api/presale")
        .then(function (response) {
          console.log(response);
          // setData(response.data);

          setpresaleID(+response.data.presaleNumber);

          setNextStagePrice(+response.data.nextPrice / 1000);

          setSoldTokens(Math.floor(+response.data.totalSale));
          setRaisedAmount(Math.floor(+response.data.amountRaised));
          setTotalTokenToSell(+response.data.tokensToSell);
          setTotalHardCap(+response.data.totalHardCap);
          settokenPrice(+response.data.price / 1000);

          let precentageBar =
            (+response.data.amountRaised / +response.data.totalHardCap) * 100;
          setProgessBar(precentageBar);

          setMinbuyToken(+formatUnits(minToken.toString()));
          setPresaleBoxLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }

  const init = async () => {
    try {
      setPresaleBoxLoading(true);
      const saleId = await presaleContract.presaleId();
      setpresaleID(+saleId);
      const saleToken = await presaleContract.SaleToken();

      const signerCon = signer ? signer : voidAccount;
      const TokenContract = new Contract(saleToken, tokenAbi, signerCon);
      const decimals = await TokenContract?.decimals();
      setTokenDecimals(+decimals);

      let totlaTokenToSell = 0;
      let totlaSoldTokens = 0;
      let totalUsdtHardcap = 0;
      let totalAmountRaised = 0;
      for (let i = +saleId; i > 0; i--) {
        const { Sold, tokensToSell, UsdtHardcap, amountRaised } =
          await presaleContract.presale(i);

        totlaTokenToSell += +formatUnits(tokensToSell.toString(), 18);
        totlaSoldTokens += +formatUnits(Sold.toString(), 18);
        totalUsdtHardcap += +formatUnits(UsdtHardcap.toString(), 6);
        totalAmountRaised += +formatUnits(amountRaised.toString(), 6);
      }

      const { price, nextStagePrice } = await presaleContract.presale(+saleId);

      setNextStagePrice(1 / +formatUnits(nextStagePrice.toString(), 18));

      setSoldTokens(Math.floor(totlaSoldTokens));
      setRaisedAmount(Math.floor(totalAmountRaised));
      setTotalTokenToSell(totlaTokenToSell);
      setTotalHardCap(totalUsdtHardcap);
      settokenPrice(1 / +formatUnits(price.toString(), 18));

      let precentageBar = (totalAmountRaised / totalUsdtHardcap) * 100;
      setProgessBar(precentageBar);
      const minToken = await presaleContract.MinTokenTobuy();
      setMinbuyToken(+formatUnits(minToken.toString()));
      setPresaleBoxLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   // init();
  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [presaleContract, account]);

  const buyHadler = async () => {
    if (account) {
      if (!enteredAmount) {
        setAlertState({
          open: true,
          message: `Error! Please enter a amount`,
          severity: "error",
        });
      } else if (enteredAmount <= 0) {
        setAlertState({
          open: true,
          message: `Error! Please enter a valid amount`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          if (token === "USDT") {
            let balanceOf = await usdtContract.balanceOf(account);

            let allowance = await usdtContract.allowance(
              account,
              presaleAddress
            );
            if (+allowance < +enteredAmount) {
              const approve = await usdtContract.approve(
                presaleAddress,
                balanceOf
              );
              await approve.wait();
            }
            const tx = await presaleContract.buyWithUSDT(
              parseUnits(enteredAmount.toString(), 6)
            );

            await tx.wait();
          } else {
            const tx = await presaleContract.buyWithEth({
              value: parseUnits(enteredAmount.toString(), 18).toString(),
            });
            await tx.wait();
          }
          setEnteredAmount("");
          // init();
          // fetchData();
          setloading(false);
          setComplete(true);
        } catch (error) {
          setloading(false);
          if (error?.data?.message) {
            setAlertState({
              open: true,
              message: error?.data?.message,
              severity: "error",
            });
          } else if (error?.reason) {
            setAlertState({
              open: true,
              message: error?.reason,
              severity: "error",
            });
          } else {
            setAlertState({
              open: true,
              message: error?.message,
              severity: "error",
            });
          }
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const calculator = async () => {
      try {
        // console.log("presaleContract", presaleContract);
        const isExcludeMinToken = await presaleContract.isExcludeMinToken(
          account
        );

        setIsExcludeMinToken(isExcludeMinToken);
        if (token === "USDT") {
          // console.log(presaleID, "effect");
          const amountRaisedBUSD = await presaleContract.usdtToTokens(
            +presaleID,
            parseUnits(enteredAmount.toString(), 6)
          );
          setreceivedTokens(
            +formatUnits(amountRaisedBUSD.toString(), tokenDecimals)
          );
        } else {
          const amountRaisedBUSD = await presaleContract.ethToTokens(
            +presaleID,
            parseUnits(enteredAmount.toString(), 18)
          );
          setreceivedTokens(
            +formatUnits(amountRaisedBUSD.toString(), tokenDecimals)
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (+enteredAmount > 0 && account) {
      calculator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredAmount, presaleContract, token, account]);

  const handleMax = async () => {
    if (token === "USDT") {
      let balanceOf = await usdtContract.balanceOf(account);
      setEnteredAmount(+formatUnits(balanceOf.toString(), 6));
    } else {
      let walletBalance = await provider.getBalance(account);
      setEnteredAmount(+formatUnits(walletBalance.toString(), 18));
    }
  };

  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Dialog
        // fullWidth
        open={openDialog}
        TransitionComponent={Transition}
        sx={{
          zIndex: 1,
          "& .MuiPaper-root": {
            backgroundColor: "#241547",
            width: matches ? "100%" : "25%",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            py={0.5}
            pl={3}
            pr={1}
            sx={{
              backgroundImage:
                "linear-gradient(90.1deg, #4889DA 0.08%, #FB497F 101.84%)",

              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Paragraph2 sx={{ fontSize: "18px" }}>Exchange</Paragraph2>
            <CloseIcon
              onClick={() => {
                setEnteredAmount("");
                setOpenDialog(!openDialog);
                setComplete(false);
              }}
              style={{
                cursor: "pointer",
                color: "#fff",
              }}
            />
          </Box>
          {loading ? (
            <Box textAlign="center" px={matches ? 1 : 2} py={matches ? 2 : 3}>
              <img src={loadingGif} alt="" width="215px" height="215px" />
              <Box mt={3} fontSize="15px" fontWeight="600" color="#fff">
                YOUR TRANSACTION IS IN PROGRESS <br />
                PLEASE WAIT
              </Box>
            </Box>
          ) : complete ? (
            <Box p={2} textAlign="center" color="#fff">
              <CheckCircleIcon sx={{ color: "#57CA5C", fontSize: "55px" }} />
              <Typography variant="body1" fontWeight="600">
                YOUR PURCHASE WAS SUCCESSFUL!
              </Typography>
              <Typography
                my={2}
                px={2}
                variant="subtitle2"
                fontSize="13px"
                fontWeight="600"
              >
                RENQ will be available for you to claim once the presale ends.
              </Typography>

              <a
                href="https://renq.io/dashboard/?ref=bought"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="body1"
                  fontSize="18px"
                  fontWeight="600"
                  borderBottom="1px solid #FB497F"
                  color="#FB497F"
                  width="fit-content"
                  m="auto"
                >
                  RenQ Dashboard
                </Typography>
              </a>
              <Typography
                mt={2}
                variant="body1"
                fontSize="18px"
                fontWeight="600"
              >
                JOIN US ON SOCIAL MEDIA TO STAY UPDATED
              </Typography>

              <Box
                mt={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <a
                  href="https://t.me/renqfinance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    bgcolor="#19B5E5"
                    sx={{
                      width: "45px",
                      height: "45px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  >
                    <TelegramIcon fontSize="large" />
                  </Box>
                </a>
                <a
                  href="https://twitter.com/RenQ_Finance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    ml={2}
                    bgcolor="#19B5E5"
                    sx={{
                      width: "45px",
                      height: "45px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  >
                    <TwitterIcon fontSize="large" />
                  </Box>
                </a>
              </Box>
            </Box>
          ) : (
            <Box py={3} px={2}>
              <Typography
                variant="subtitle2"
                fontSize="13px"
                fontWeight="600"
                pl={2}
              >
                You Pay
              </Typography>
              <Box
                sx={{
                  border: "1px solid #EF4D84",
                  borderRadius: "20px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextInput
                  fullWidth
                  placeholder="Enter amount"
                  type="number"
                  value={enteredAmount}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setEnteredAmount(e.target.value);
                  }}
                />
                <Box pr={1} display="flex" alignItems="center">
                  <img
                    src={token === "USDT" ? usdt : ethBlack}
                    alt=""
                    style={{
                      marginRight: "4px",
                      width: "13px",
                    }}
                  />
                  {token === "USDT" ? "USDT" : "ETH"}
                </Box>
              </Box>

              <Box pt="4px" pr={3} display="flex" justifyContent="flex-end">
                {token == "USDT" && (
                  <Typography
                    variant="subtitle2"
                    onClick={handleMax}
                    sx={{
                      padding: "0 4px",
                      borderRadius: "5px",
                      background: "#fff",
                      color: "#000",
                      fontSize: "12px",
                      fontWeight: "700",
                      width: "fit-content",
                      cursor: "pointer",
                    }}
                  >
                    MAX
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  fontSize="13px"
                  fontWeight="600"
                  pl={2}
                >
                  You Receive
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #EF4D84",
                    borderRadius: "20px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextInput
                    fullWidth
                    placeholder="Enter amount"
                    type="number"
                    value={
                      +enteredAmount > 0
                        ? parseFloat(receivedTokens).toFixed(2)
                        : ""
                    }
                  />
                  <Box pr={1} display="flex" alignItems="baseline">
                    <img
                      src={renqLogo}
                      alt=""
                      style={{
                        marginRight: "4px",
                        width: "12px",
                        height: "17px",
                      }}
                    />
                    RENQ
                  </Box>
                </Box>
              </Box>

              <Typography
                variant="subtitle2"
                color="red"
                fontSize="13px"
                display={
                  receivedTokens < minbuyToken || enteredAmount < 0
                    ? "block"
                    : "none"
                }
                pl={1}
              >
                Value must be greater than or equal to {minbuyToken}.
              </Typography>

              <Typography
                mt={2}
                variant="subtitle2"
                fontSize="13px"
                fontWeight="600"
                textAlign="center"
              >
                Minimum Buy: {minbuyToken} RENQ
              </Typography>
              <CustomButton
                sx={{
                  width: "100%",
                  mt: 3,
                }}
                onClick={buyHadler}
                disabled={
                  (receivedTokens > minbuyToken && enteredAmount > 0) ||
                  isExcludeMinToken
                    ? false
                    : true
                }
              >
                Buy now
              </CustomButton>
            </Box>
          )}
        </Box>
      </Dialog>

      <Box
        sx={{
          backgroundImage: `url(${homeimg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100%",
        }}
      >
        <Header />
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            display="flex"
            py={7}
          >
            <Grid item md={6} xs={12}>
              <Box>
                {/* <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.08)",
                    fontFamily: "'Prompt'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "19px",
                    borderRadius: "29px",
                    width: "fit-content",
                    height: "33px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    pr: 1.5,
                    py: 2.5,
                  }}
                >
                  <Paragraph2
                    sx={{
                      width: "100px",
                      height: "26px",
                      color: "#000",
                      background: "#FFFFFF",
                      borderRadius: "29px",
                      m: "5px",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                      py: 2,
                      mr: 1,
                    }}
                  >
                    Welcome
                  </Paragraph2>
                  To The Future Of DeFi
                </Box> */}

                {/* Main Heading */}

                <MianHeading mt={3}>
                  A Unified Platform to a Decentralized World
                </MianHeading>
                <Paragraph my={4} pr={matches ? 0 : 2}>
                  Renq aims to connect all isolated blockchains and establish a
                  cross-chain asset exchange network, providing all necessary
                  underlying support for the DeFi ecosystem. Let every digital
                  asset holder experience a truly safe, free and transparent
                  DeFi service.
                </Paragraph>

                <Box my={4} gap={3} display={"flex"} flexWrap="wrap">
                  {/* <CustomButton
                    endIcon={
                      <ExpandCircleDownIcon
                        style={{
                          fontSize: "25px",
                          transform: "rotate(270deg)",
                        }}
                      />
                    }
                    sx={{ width: { xs: "100%", md: "auto" }, pl: 1.5 }}
                  >
                    REGISTER & BUY TOKEN NOW
                  </CustomButton> */}
                  <a
                    href="https://renq.io/whitepaper.pdf"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <CustomButton
                      startIcon={
                        <DownloadForOfflineIcon style={{ fontSize: "25px" }} />
                      }
                      mt={matches ? 2 : 0}
                      sx={{ width: "150px", pr: 1.5 }}
                    >
                      WhitePaper
                    </CustomButton>
                  </a>
                  <a
                    href="https://renq.io/certik-audit/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <CustomButton
                      // startIcon={
                      //   <DownloadForOfflineIcon style={{ fontSize: "25px" }} />
                      // }
                      mt={matches ? 2 : 0}
                      sx={{ width: "150px", pr: 1.5 }}
                    >
                      Certik Audit
                    </CustomButton>
                  </a>
                </Box>

                {/*  icon of this sections */}

                <Box
                  gap={{ xs: 1.5, md: 3 }}
                  display="flex"
                  justifyContent="center"
                >
                  {icons.map(({ link, icon }, i) => {
                    return (
                      <a
                        key={i}
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <IconBox>{icon}</IconBox>
                      </a>
                    );
                  })}
                </Box>
              </Box>
            </Grid>

            {/* Presale stage 1 Card data  */}

            <Grid item md={6} xs={12}>
              {presaleBoxLoading ? (
                <Box
                  p={2}
                  sx={{
                    background: "rgba(255, 255, 255, 0.06)",
                    backdropFilter: "blur(9px)",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={loadingGif}
                    alt=""
                    width={matches ? "275px" : "400px"}
                    height={matches ? "275px" : "400px"}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.06)",
                    border: "1px solid #7A7A7A",
                    backdropFilter: "blur(9px)",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "'Prompt'",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "34px",
                        lineHeight: "42px",
                        color: "#FFFFFF",
                        mb: 2,
                        textTransform: "uppercase",
                      }}
                    >
                      Presale {presaleID}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pb: 1,
                      }}
                    >
                      <Paragraph2
                        sx={{
                          fontSize: matches ? "20px" : "28px",
                          fontWeight: "700",
                        }}
                      >
                        1 RENQ = {parseFloat(tokenPrice).toFixed(3)} USDT
                      </Paragraph2>
                    </Box>

                    <Box
                      mt={2}
                      textAlign="center"
                      fontSize={matches ? "16px" : "24px"}
                      fontWeight="700"
                      color="#388E8D"
                    >
                      NEXT STAGE PRICE = {parseFloat(nextStagePrice).toFixed(3)}{" "}
                      USDT
                    </Box>
                    {/* <Paragraph2 mt={1} sx={{ fontSize: "16px" }}>
                    Presale {presaleID} Ends Soon
                  </Paragraph2>
                  <Paragraph mt={2}>
                    Some small line saying price will increase soon so buy now
                  </Paragraph> */}

                    {/* Progress bar */}
                    <Box
                      sx={{
                        position: "relative",
                        background: "#324182",
                        borderRadius: "29px",
                        margin: "15px 0",
                        height: 36,
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage:
                            "linear-gradient(90.1deg, #4889DA 0.08%, #FB497F 101.84%)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "29px",
                          height: 36,
                          opacity: 1,
                          transition: "1s ease 0.3s",
                          width: `${progressBar}%`,
                        }}
                      ></div>
                      <Box
                        sx={{
                          position: "absolute",
                          top: 6,
                          left: "45%",
                        }}
                      >
                        {parseFloat(progressBar).toFixed(2)} %
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <Paragraph2
                          sx={{
                            fontSize: matches ? "15px" : "17px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                          }}
                        >
                          SOLD - {toLocalFormat(soldTokens)}
                          <span style={{ fontSize: "20px" }}>/</span>
                          {toLocalFormat(totalTokenToSell)}
                        </Paragraph2>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 1,
                        }}
                      >
                        <Paragraph2
                          sx={{
                            fontSize: matches ? "15px" : "17px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                          }}
                        >
                          RAISED - ${toLocalFormat(raisedAmount)}
                          <span style={{ fontSize: "20px" }}>/</span>$
                          {toLocalFormat(totalHardCap)}
                        </Paragraph2>
                      </Box>

                      {/* <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        mt: -1,
                      }}
                    >
                      <Paragraph2>Soft Cap</Paragraph2>
                      <Paragraph2>Crowdsale</Paragraph2>
                      <Paragraph2>Hard cap</Paragraph2>
                    </Box> */}
                    </Box>
                    {/* {account ? (
                    <Box mt={2.5}>
                      <TextField
                        placeholder="Enter Amount"
                        type="number"
                        value={enteredAmount}
                        onChange={(e) => setEnteredAmount(e.target.value)}
                        sx={{
                          width: "100%",
                          fontWeight: "400",
                          height: "20px",
                          borderRadius: "25px",
                          "& label.Mui-focused": {
                            color: "#ffffff",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "44px",
                              border: "1px solid #FB497F",
                            },
                            "&:hover fieldset": {
                              borderRadius: "44px",
                              border: "1px solid #FB497F",
                            },
                            "&.Mui-focused fieldset": {
                              borderRadius: "44px",
                              border: "1px solid #FB497F",
                            },
                          },
                          input: {
                            color: "white",
                            fontSize: { xs: "12px", md: "12px" },
                            "&::placeholder": {
                              textOverflow: "ellipsis !important",
                              color: "#ffffff",
                            },
                          },
                          background: "transparent",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ marginRight: "40px" }}
                            >
                              <Box
                                display="flex"
                                id="demo-positioned-button"
                                aria-controls={
                                  open1 ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open1 ? "true" : undefined}
                                onClick={handleClick}
                                ml={-1}
                                sx={{
                                  background:
                                    "linear-gradient(90.1deg, #4889DA 0.08%, #FB497F 101.84%)",
                                  borderRadius: "44px",
                                  transition: "0.6s",
                                  height: "38px",
                                  padding: "13px",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  color: "white",
                                }}
                              >
                                {token === "USDT" ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "20px",
                                        marginRight: "8px",
                                      }}
                                      src={usdt}
                                      alt=""
                                    />
                                    <Box
                                      color="#fff"
                                      fontFamily="Open Sans"
                                      fontSize="12px"
                                    >
                                      USDT
                                    </Box>
                                    <ArrowDropDownIcon
                                      style={{ color: "#FFF" }}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      src={eth}
                                      style={{
                                        width: "25px",
                                        marginRight: "8px",
                                      }}
                                      alt=""
                                    />
                                    <Box
                                      color="#fff"
                                      fontFamily="Open Sans"
                                      fontSize="12px"
                                    >
                                      ETH
                                    </Box>
                                    <ArrowDropDownIcon
                                      style={{ color: "#FFF" }}
                                    />
                                  </Box>
                                )}
                              </Box>
                              <Menu
                                sx={{
                                  "& .MuiMenu-list": {
                                    background: "#000000",
                                    color: "#fff",
                                  },
                                }}
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleClose();
                                    setToken("USDT");
                                  }}
                                  disableRipple
                                >
                                  <Box display="flex">
                                    <img src={usdt} width="30px" alt="" />
                                    <Typography
                                      ml={1}
                                      display="flex"
                                      alignItems="center"
                                      sx={{
                                        fontFamily: "Open Sans",
                                        fontSize: "12px",
                                      }}
                                    >
                                      USDT
                                    </Typography>
                                  </Box>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleClose();
                                    setToken("ETH");
                                  }}
                                  disableRipple
                                >
                                  <Box display="flex">
                                    <img src={eth} width="30px" alt="" />
                                    <Typography
                                      ml={1.5}
                                      display="flex"
                                      alignItems="center"
                                      sx={{
                                        fontFamily: "Open Sans",
                                        fontSize: "12px",
                                      }}
                                    >
                                      ETH
                                    </Typography>
                                  </Box>
                                </MenuItem>
                              </Menu>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  ) : (
                    ""
                  )}

                  {account ? (
                    <Box mt={5}>
                      <Box mb={2} textAlign="center">
                        <ArrowDownwardSharpIcon />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                          borderRadius: "44px",
                          border: "1px solid #FB497F",
                          width: "100%",
                          padding: "5px",
                          color: "#ffffff",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            background:
                              "linear-gradient(90.1deg, #4889DA 0.08%, #FB497F 101.84%)",
                            borderRadius: "44px",
                            transition: "0.6s",
                            height: "38px",
                            width: "110px",
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "#fff",
                            marginRight: "40px",
                          }}
                        >
                          <img
                            src={renqlogo}
                            width="12px"
                            style={{ marginRight: "10px" }}
                            alt=""
                          />
                          RNQ
                        </Box>
                        {+enteredAmount > 0
                          ? parseFloat(receivedTokens).toFixed(2)
                          : "0.00"}
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )} */}

                    {account ? (
                      <>
                        <CustomButton
                          sx={{
                            width: "100%",
                            mt: 4,
                          }}
                          onClick={() => {
                            setOpenDialog(true);
                            // buyHadler();
                            setToken("USDT");
                          }}
                        >
                          Buy rnq with usdt
                        </CustomButton>
                        <CustomButton
                          sx={{
                            width: "100%",
                            mt: 2,
                          }}
                          onClick={() => {
                            setOpenDialog(true);
                            setToken("ETH");
                          }}
                        >
                          Buy rnq with eth
                        </CustomButton>
                      </>
                    ) : (
                      <CustomButton
                        sx={{
                          width: "100%",
                          mt: 4,
                        }}
                        onClick={() => connect()}
                      >
                        Connect Wallet
                      </CustomButton>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        mt: 4,
                      }}
                    >
                      <a
                        href="https://renq.io/troubleshoot"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                        }}
                      >
                        <Typography
                          variant="body1"
                          borderBottom="0.5px solid #7A7A7A"
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px",
                            "&:hover": {
                              color: "#FB497F",
                              borderBottom: "0.5px solid #FB497F",
                            },
                          }}
                        >
                          <b> Getting Error? Click Here</b>
                        </Typography>
                      </a>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        my: 4,
                      }}
                    >
                      <a
                        href="https://renq.io/how-to-buy"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <Typography
                          variant="body1"
                          borderBottom="0.5px solid #7A7A7A"
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px",
                            "&:hover": {
                              color: "#FB497F",
                              borderBottom: "0.5px solid #FB497F",
                            },
                          }}
                        >
                          <b> How to buy?</b>
                        </Typography>
                      </a>
                      <a
                        href="https://renq.io/new-to-crypto"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <Typography
                          variant="body1"
                          borderBottom="0.5px solid #7A7A7A"
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px",
                            "&:hover": {
                              color: "#FB497F",
                              borderBottom: "0.5px solid #FB497F",
                            },
                          }}
                        >
                          <b>New to crypto?</b>
                        </Typography>
                      </a>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "rgba(255, 255, 255, 0.05)",
                      py: 1.5,
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                    }}
                  >
                    {cardImage.map((item, index) => {
                      return (
                        <img
                          key={index}
                          src={item}
                          alt=""
                          style={{ width: "30px", height: "30px" }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

// export async function getServerSideProps() {
//   try {
//     const response = await axios.get("/api/presale");
//     const data = response.data;
//     console.log(data);
//     return {
//       props: { data },
//     };
//   } catch (err) {
//     // err.statusCode = 404;
//     console.log(err);
//     return {
//       props: {},
//     };
//   }
// }

export default Home;
